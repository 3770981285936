/* eslint-disable no-console */
// import _ from 'lodash';
import { http } from '../plugin/http2-common';

const DISCLAIMER_UPDATE_API = '/disclaimer/update/';
// eslint-disable-next-line no-unused-vars
const DISCLAIMER_GET_API = '/disclaimer/list/';

class DisclaimerService {
    // eslint-disable-next-line no-unused-vars
    getDisclaimerList(surveyId) {
        return http.get(DISCLAIMER_GET_API + surveyId);
    }

    updateDisclaimer(surveyId, data) {
        return http
            .put(DISCLAIMER_UPDATE_API + surveyId, data);
    }
}

export default new DisclaimerService();