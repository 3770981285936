<template>
  <!-- <div style="overflow: auto; margin: 0 -6%; padding: 0 6%;" class="common-department"> -->
  <div style="margin: 0 -6%; padding: 0 6%" class="common-department">
    <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
      <div class="user-infor">
        <span>{{ countryAndLanguage }}</span>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light topnav">
        <div id="navbarText" :class="getTableHeaderClass + ' collapse navbar-collapse'">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link
                exact
                class="nav-link pr-0 pl-0"
                to="/database/product-database"
                style=""
              >
                Product Database
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                exact
                class="nav-link pr-0 pl-0"
                to="/database/disclaimer"
              >
                Disclaimer Database
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <template v-if="!editable">
              <li class="nav-item">
                <b-button
                  type="button"
                  variant="outline-info custom-btn"
                  @click="turnOnEditable()"
                >
                  Edit content
                </b-button>
              </li>
            </template>
            <template v-else>
              <li class="nav-item">
                <b-button
                  variant="outline-info custom-btn btn-save"
                  @click="showModalSaveAll = true"
                >
                  Save
                </b-button>
                <b-button
                  variant="outline-info custom-btn"
                  @click="showModalCancelChange = true"
                >
                  Cancel
                </b-button>
              </li>
            </template>
          </ul>
        </div>
      </nav>
    </div>
    <div>
      <b-row>
        <b-col cols="10">
          <div style="margin-top:83px">
            <p>You can create the text for the disclaimer to be displayed in a pop-up and activate its display on all pages with content related to the Product Database, Questionnaire, Persona/Recommendation. The disclaimer will appear on the app, web version and the emailers.</p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div style="margin: 0; padding: 0" class="table-responsive">
      <table
        id="table-1"
        class="table-sm table custom-table"
        responsive
        sm
        borderless
      >
        <thead>
          <tr>
            <th style="width: 20%" scope="col" :class="getTableHeaderClass">
              <strong> DISCLAIMER TITLE </strong>
            </th>
            <th style="width: 65%" scope="col" :class="getTableHeaderClass">
              <strong> TEXT TO DISPLAY IN POP-UP </strong>
            </th>
            <th v-if="editable" style="width: 15%" scope="col" :class="getTableHeaderClass">
              <strong> OTHER ACTIONS </strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="editable"> 
            <tr v-for="(item, index) in items" :key="item.id + item.name + item.description">
              <td>
                <CustomEditor
                  v-model="item.name" 
                  type="textarea"
                  class-name="form-control" 
                  :rows="3"
                  :maxlength="200"
                />
              </td>
              <td>
                <CustomEditor
                  v-model="item.description" type="textarea"
                  class-name="form-control"
                  :rows="4"
                  :maxlength="600"
                />
              </td>
              <td>
                <b-icon
                  class="pointer"
                  icon="x-circle"
                  variant="danger"
                  @click="removeItem(index, item);"
                />
              </td>
            </tr>
            <tr class="table-borderless">
              <td>
                <b-button
                  variant="btn btn-info"
                  style="
                    margin-bottom: 10px;
                    width: 100%;
                    background: #006db6;
                    color: white;
                  "
                  @click="addNewItem()"
                >
                  + add new disclaimer
                </b-button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(item) in items" :key="item.id">
              <td>
                <CustomEditor
                  v-model="item.name" 
                  main-class-name="disclaimer-name"
                  class-name="form-control" 
                />
              </td>
              <td>
                <CustomEditor v-model="item.description" class-name="form-control" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!--######################### The Modal SAVE ALL ###########################################################################-->
    <div v-if="showModalSaveAll">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>
                      All changes will be saved.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        saveAll();
                        showModalSaveAll = false;
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="cancelSaveAllEditable();"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal SAVE All END ###########################################################################-->

    <!--######################### The Modal CANCEL ###########################################################################-->
    <div v-if="showModalCancelChange">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>
                      All changes will be removed.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        reloadPage();
                        showModalCancelChange = false;
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="cancelEditable();"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal CANCEL END ###########################################################################-->

    <!--######################### The Modal DELETE ###########################################################################-->
    <div v-if="showModalDelete">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal-delete">
                    <p>
                      NOTE: All text disclaimer linked to “{{ titleDeletedItem }}“ also be removed as disclaimer “{{ titleDeletedItem }}“ is being removed.<br />
                      Select “confirm” to continue.
                    </p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-info"
                      style="
                        background: #006db6;
                        margin-right: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="
                        showModalDelete = false;
                        removeConfirm();
                      "
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showModalDelete = false"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal DELETE END ###########################################################################-->
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="showError">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content custom-dialog">
                <div class="modal-body">
                  <div class="body-text-modal">
                    <p>{{ message }}<br /></p>
                  </div>
                  <div class="btn-modal">
                    <button
                      type="button"
                      class="btn btn-light"
                      style="
                        color: #006db6;
                        margin-left: 20px;
                        width: 127px;
                        height: 40px;
                      "
                      @click="showError = false"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
import { fixHeaderTable } from "../../common/CommonUtils";
import CustomEditor from "../../components/custom/CustomEditor";
import DisclaimerService from "../../service/DisclaimerService";

export default {
  name: "Disclaimer",
  components: {
    CustomEditor
  },
  data() {
    const surveyId = this.$store.state.survey.id;
    return {
      surveyId: surveyId <= 0 ? 1 : surveyId,
      editable: (this.$store.state.survey.isSurveyEditing = false) && false,
      items: [],
      titleDeletedItem: "",
      showError: false,
      showModalSaveAll: false,
      showModalDelete: false,
      showModalCancelChange: false,
      removeIndex: 0,
      nextItemId: 0
    };
  },
  computed: {
    countryAndLanguage() {
      return this.$store.state.survey.countryAndLanguage;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "edit-th-content"
            : "normal-th-content bg-light"
    },
  },
  mounted() {
    this.fetch();
  },
  updated() {
    fixHeaderTable("table-1", "header-nav", this.editable);
  },
  methods: {
    fetch() {
      let loader = this.$loading.show();
      DisclaimerService.getDisclaimerList(this.surveyId).then((response) => {
        this.items = response.data.result;
        let nextId = 0
        this.items.forEach(item => {
          if (item.id > nextId) {
            nextId = item.id;
          }
          // console.log(item.id, item.name, item.description);
        })
        this.nextItemId = nextId + 1;
        loader.hide();
      });
    },

    changeEditableState(state) {
      this.editable = state;
      this.$store.state.survey.isSurveyEditing = state;
      if (state === true) {
        this.$store.state.survey.editingScreenSaveFunc = this.saveAll;
      }
    },
    turnOnEditable() {
      this.changeEditableState(true);
    },
    
    cancelEditable() {
        this.showModalCancelChange = false;
    },

    cancelSaveAllEditable() {
        this.showModalSaveAll = false;
    },

    turnOffEditable() {
      this.changeEditableState(false);
      this.fetch();
    },

    reloadPage() {
      this.turnOffEditable();
    },

    checkMaxValue($event) {
      if (this.editValue != undefined && this.editValue.length >= this.validateMaxValue) {
        $event.preventDefault();
        return;
      }
    },

    removeItemAtIndex(index) {
      const tempItems = this.items.filter(
        (item, i) => (index !== i)
      );
      this.items = tempItems;
      // console.log("deleted", this.items);
    },

    removeConfirm() {
      this.removeItemAtIndex(this.removeIndex);
    },

    removeItem(index, item) {
      // console.log("delete", index, item);
      if (item.name.length === 0 && item.description.length === 0) {
        this.removeItemAtIndex(index);
        return;
      }
      this.titleDeletedItem = item.name;
      this.removeIndex = index;
      this.showModalDelete = true;
    },

    onPaste($event) {
      const value = $event.clipboardData.getData("text");
      if (value.length >= this.validateMaxValue) {
        this.editValue = this.editValue.substring(199);
        $event.preventDefault();
        return;
      }
    },

    addNewItem(){
      this.items.push({
        id: this.nextItemId,
        name: "",
        description: ""
      });
      this.nextItemId += 1;
    },

    saveAll(chainFunc) {
      // console.log("saving", this.items);
      const newItems = [];
      this.items.forEach(item => {
        if (item.name.length === 0 && item.description.length === 0) {
          return;
        }
        newItems.push({
          name: item.name, 
          description: item.description
        });
      });
      let loader = this.$loading.show();
      DisclaimerService.updateDisclaimer(this.surveyId, newItems).then((response) => {
        this.items = response.data.result;
        let nextId = 0
        this.items.forEach(item => {
          if (item.id > nextId) {
            nextId = item.id;
          } 
          // console.log(item.id, item.name, item.description);
        })
        this.nextItemId = nextId + 1;
        this.changeEditableState(false);
        if (chainFunc !== undefined)
          chainFunc()
        loader.hide();
      })
      .catch((e) => {
        console.log(e);
        // this.changeEditableState(false);
        loader.hide();
      });
    },
  },
};
</script>

<style scoped>
.bold-border {
  border-top: 2px solid rgb(119, 119, 119);
}

.btn-tooltip {
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 71%, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.tooltip-img {
  cursor: pointer;
}
.custom-dialog {
  width: 467px;
  height: 283px;
  background: #4b4b4b;
}

.body-text-modal {
  width: 294px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.body-text-modal-delete {
  width: 327px;
  height: 56px;
  margin: 0 auto;
  text-align: center;
  padding: 25px 0px 35px 0px;
  margin-bottom: 91px;
  font-size: 15px;
  line-height: 25px;
  color: white;
}

.btn-modal {
  display: flex;
  justify-content: center;
}

.nav-link {
  font-size: 20px;
}

.padding-right {
  padding-right: 5%;
}

.product-database {
  font-size: 15px;
  line-height: 23px;
  font-family: "Montserrat", sans-serif;
}

.title-description {
  margin: 40px 0 28px 0;
}

.content {
  padding: 24px;
}

.custom-table {
  margin-top: 1.2rem;
}

td {
  word-break: break-word;
}

thead > tr > th {
  font-weight: bold !important;
  font-size: 13px;
  line-height: 23px;
  padding: 1rem 0.3rem;
  background: #f6f6f6;
}

table > thead {
  position: sticky !important;
  top: 0;
  z-index: 1000;
}

.category-name {
  color: #006db6;
  font-weight: bold;
}

.category-img {
  /* padding: 10px 0px 10px 10px;
  width: 30px;
  height: 40px; */
  border: .5px solid #848484;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}


.icon-img {
  margin: 0 auto;
}

.icon-img > img {
  border: .5px solid #848484;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

/* .icon-img > b-icon {
  margin-left: 10px;
} */

#falseinput img {
  width: 20px;
  height: 20px;
}

a {
  color: #0065fc;
}

.editor {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
}

.editor textarea {
  border: none!important;
  margin-bottom: 20px;
}

.editor textarea:focus, .editor textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

.editor span {
  color: #768192;
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 10px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: black;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 9px;
  content: " ";
  display: block;
  background: black;
}
</style>